.alert {
  width: 100%;
  max-width: 840px;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &.success {
    background-color: var(--color-success);
    color: var(--text-color-success);
  }

  &.warning {
    background-color: var(--color-warning);
    color: var(--text-color-warning);
  }

  &.error {
    background-color: var(--color-error);
    color: var(--text-color-error);
  }

  &.info {
    background-color: var(--color-info);
    color: var(--text-color-info);
  }
}
