@import 'grid-variables';
@import '../breakpoints';
@import 'grid-mixins';

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: $grid-gutter / -2;
  margin-right: $grid-gutter / -2;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

@include make-grid-columns();

@each $breakpoint, $infix in $breakpoint-infixs {
  @if ($breakpoint== 'xsmall') {
    @include loop-grid-columns($grid-columns, $infix);
  } @else {
    @include bp-gt($breakpoint) {
      @include loop-grid-columns($grid-columns, $infix);
    }
  }
}
