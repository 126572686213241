/**
Usage:

add the
panelClass: 'smartportal-dialog',
on the dialog.open "config"

 */

$spacing: 20px;

.smartportal-dialog {
  .mat-mdc-dialog-title {
    padding: $spacing $spacing 0;

    font-family: inherit !important;
    line-height: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit !important;
  }

  .mdc-dialog__title::before {
    display: none;
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    padding: $spacing;
  }

  .mat-mdc-dialog-actions {
    padding: 0 $spacing $spacing;
    display: flex;
    gap: 5px;
    justify-content: flex-end;

    & > *:nth-child(2) {
      margin-left: auto;

      &:last-child {
        margin-left: 0;
      }
    }
  }
}
