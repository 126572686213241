$gutter: 16px !default;

:root {
  /* Backgroundcolors*/
  --color-warning: #fff3cd;
  --color-error: #f8d7da;
  --color-success: #d4edda;
  --color-info: #cce5ff;
  /* Textcolors*/
  --text-color-warning: #856404;
  --text-color-error: #721c24;
  --text-color-success: #155724;
  --text-color-info: #004085;
  /* Fonts*/
  --rootFontFamily: Roboto;
  --fs-bl-M-2: 24px;
  --fs-bl-M-1: 18px;
  --fs-bl: 14px;
  --fs-bl-m-1: 12px;

  --mdc-linear-progress-active-indicator-color: green !important;
}
