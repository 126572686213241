.info-card_field {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  /* TODO mobile breakpoint */
}
.info-card_field > * {
  word-break: break-word;
}
.info-card_field > .label {
  font-weight: bold;
  margin-right: 10px;
  min-width: 170px;
}

.warning-tag {
  background-color: var(--color-warning);
  display: inline-flex;
  i {
    font-size: 20px;
    color: inherit;
  }
}
