@import 'styles/transitions';
@import 'styles/variables';
@import 'styles/mat-theme';
@import 'styles/scrollbar';
@import 'styles/theme';
@import 'styles/breakpoints';

@import 'styles/lib/datepicker-theme';
@import 'styles/lib/ngx-toastr';
@import 'styles/grid/grid';
@import 'styles/layout';
@import 'styles/dialog';
@import 'styles/infocard';

@import 'styles/lib/matero-override';
@import 'styles/lib/material-override';
@import 'styles/alert';
@import 'styles/autocomplete';

@import 'styles/globals';
@include mat-datetimepicker-theme($theme);

// global styling for long column names in mtx grid column menu
.mtx-grid-column-menu-item {
  width: max-content;
  .mtx-grid-column-menu-item-label {
    width: min-content;
  }
}
