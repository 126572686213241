// mtx grid

@mixin custom-table-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $background: map-get($theme, 'background');
  $foreground: map-get($theme, 'foreground');

  $row-hover-bg: mat-color($mat-indigo, 50);
  $row-odd-bg: mat-color($mat-gray, 100);

  $row-hover-bg-dark: mat-color($mat-blue-gray, 900);
  // $row-odd-bg-dark: lighten(mat-color($mat-gray, 900), 10%);
  $row-odd-bg-dark: mat-color($mat-gray, 800);

  mtx-grid.mtx-grid {
    .mat-table {
      &.mat-table-striped {
        .mat-row-odd {
          background-color: if($is-dark-theme, $row-odd-bg-dark, $row-odd-bg);
        }
      }

      &.mat-table-hover {
        .mat-row {
          &:hover {
            background-color: if(
              $is-dark-theme,
              $row-hover-bg-dark,
              $row-hover-bg
            );
          }
        }
      }

      .mat-row {
        &.selected {
          background-color: if(
            $is-dark-theme,
            $row-hover-bg-dark,
            $row-hover-bg
          );
        }
      }
    }
  }
}

mtx-grid.mtx-grid {
  border: none;
}

mtx-grid.mtx-grid .mat-mdc-paginator {
  border-top: none;
}

.mtx-grid-toolbar {
  border: none;
}

.mtx-grid-sidebar {
  border: none !important;
}

.mtx-grid-toolbar-content {
  flex: 1;
}

.mtx-grid-toolbar-actions {
  .mdc-button {
    color: var(--theme-accent-500) !important;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.6px;
  }

  //.mat-mdc-button{
  //  height: 42px !important;
  //}
}


.ng-select .ng-select-container .ng-value-container .ng-input {
  z-index: 2;
}
