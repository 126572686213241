@use '@angular/material' as mat;
$mat-datetimepicker-selected-today-box-shadow-width: 1px;

@mixin mat-datetimepicker-theme($theme) {
  $primary: mat.get-theme-color($theme, primary);

  .mat-datetimepicker-calendar-header {
    background-color: $primary;
    color: white;
  }

  .mat-datetimepicker-calendar-body-cell-content {
    &.mat-datetimepicker-calendar-body-selected {
      background-color: $primary;
      color: white;
    }

    &.mat-datetimepicker-calendar-body-disabled
      > .mat-datetimepicker-calendar-body-selected {
      background-color: rgba($primary, 0.4);
    }

    &.mat-datetimepicker-calendar-body-today {
      &:not(.mat-datetimepicker-calendar-body-selected) {
        border-color: $primary;
      }

      &.mat-datetimepicker-calendar-body-selected {
        box-shadow: inset 0 0 0
          $mat-datetimepicker-selected-today-box-shadow-width
          mat.get-theme-color($theme, primary, default-contrast);
      }
    }
  }

  .mat-datetimepicker-clock-center {
    background-color: $primary;
  }

  .mat-datetimepicker-clock-hand {
    background-color: $primary;

    &::before {
      background-color: $primary;
    }
  }

  .mat-datetimepicker-clock-cell {
    &.mat-datetimepicker-clock-cell-selected {
      background-color: mat.get-theme-color($theme, primary, 400);
    }
  }
}
