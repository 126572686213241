@use '@angular/material' as mat;
@include mat.core();

$palette-primary: (
  50: var(--theme-primary-50),
  100: var(--theme-primary-100),
  200: var(--theme-primary-200),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700),
  ),
);

$palette-accent: (
  50: var(--theme-accent-50),
  100: var(--theme-accent-100),
  200: var(--theme-accent-200),
  300: var(--theme-accent-300),
  400: var(--theme-accent-400),
  500: var(--theme-accent-500),
  600: var(--theme-accent-600),
  700: var(--theme-accent-700),
  800: var(--theme-accent-800),
  900: var(--theme-accent-900),
  A100: var(--theme-accent-A100),
  A200: var(--theme-accent-A200),
  A400: var(--theme-accent-A400),
  A700: var(--theme-accent-A700),
  contrast: (
    50: var(--theme-accent-contrast-50),
    100: var(--theme-accent-contrast-100),
    200: var(--theme-accent-contrast-200),
    300: var(--theme-accent-contrast-300),
    400: var(--theme-accent-contrast-400),
    500: var(--theme-accent-contrast-500),
    600: var(--theme-accent-contrast-600),
    700: var(--theme-accent-contrast-700),
    800: var(--theme-accent-contrast-800),
    900: var(--theme-accent-contrast-900),
    A100: var(--theme-accent-contrast-A100),
    A200: var(--theme-accent-contrast-A200),
    A400: var(--theme-accent-contrast-A400),
    A700: var(--theme-accent-contrast-A700),
  ),
);

$theme-primary: mat.define-palette($palette-primary);
$theme-accent: mat.define-palette($palette-accent);
$theme-warn: mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

@include mat.all-component-themes($theme);

.mdc-linear-progress__buffer-bar {
  background-color: #e0e0e0 !important;
}

.mat-form-field-full-width {
  width: 100%;
}

::ng-deep .mat-checkbox-checked .mat-checkbox-background {
  background-color: #fa3131 !important;
}

// Space between fields
.description-margin formly-wrapper-mat-form-field .mat-mdc-form-field,
.description-margin formly-wrapper-mat-form-field .mat-form-field {
  margin-bottom: 45px;
}

formly-wrapper-mat-form-field .mat-mdc-form-field,
formly-wrapper-mat-form-field .mat-form-field {
  margin-bottom: 12px;
}

.margin-top-edit-room-modal formly-wrapper-mat-form-field > mat-form-field {
  margin-top: 5px;
}

// Checkbox color // todo: theme?
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: white !important;
}
