.su-autocomplete_option-container {
  display: flex;
}

.su-autocomplete_option-container > .mdc-list-item__primary-text {
  width: 100%;
}

.su-autocomplete_row {
  display: flex;
  flex-wrap: wrap;
  margin-left: $grid-gutter / -2;
  margin-right: $grid-gutter / -2;

  & > * {
    flex: 1;
  }
}
